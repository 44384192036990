const labelList = {

  // App name
  "app_name": `Club 100`,

  //How to Earn
  "how_to_earn_header": `Join Club 100, Joy Together & Enjoy Endless Offers!`,
  "how_to_earn_sub_header": `Become Club 100 member to enjoy all member privileges`,
  "how_to_earn_register": `Register Now`,
  "how_to_earn_privilege_1_header": `Total Value of HK$30 Welcome e-Coupons`,
  "how_to_earn_privilege_1_description": `Receive six $5 e-Coupons within 1 working day upon successful registration.`,
  "how_to_earn_privilege_1_tnc": `Terms and Conditions apply.`,
  "how_to_earn_privilege_2_header": `Weekly Visit Rewards`,
  "how_to_earn_privilege_2_description": `Upon eligible purchase amount of $27 or above in a single transaction = 1 eligible transaction`,
  "how_to_earn_privilege_2_description_calculation": `Weekly offer (from Mon to Sun):`,
  "how_to_earn_privilege_2_description_calculation_3_times": `3 transactions of $27+`,
  "how_to_earn_privilege_2_description_calculation_3_times_reward": `$5 e-Cash`,
  "how_to_earn_privilege_2_description_calculation_5_times": `2 more transactions of $27+ (5 in total)`,
  "how_to_earn_privilege_2_description_calculation_5_times_reward": `$10 e-Cash`,
  "how_to_earn_privilege_2_popup_header": `Weekly Visit Reward`,
  "how_to_earn_privilege_2_popup_description": `Each member is eligible to earn "e-Cash" from the 1st to 15th transactions with purchase amount of $27 or above within a single day only`,
  "how_to_earn_privilege_2_popup_description_time": `Remarks: The daily cut off time for “e-Cash” and “Bonus Points” is 00:00.`,
  "how_to_earn_privilege_2_popup_description_remarks": `* (Number of transactions will be recalculated after 5 transactions or on every Monday)`,
  "how_to_earn_privilege_3_header": `"Bonus Points" Reward`,
  "how_to_earn_privilege_3_description": `Redeem "e-Coupon" or gift with "Bonus Points"# on “CafedeCoral” Mobile App`,
  "how_to_earn_privilege_3_description_calculation": `$1 = 1 "Bonus Point"`,
  "how_to_earn_privilege_3_popup_header": `"Bonus Points" Reward`,
  "how_to_earn_privilege_3_popup_description": `Each member is eligible to earn "Bonus Points" from the 1st to 15th transactions within a single day only`,
  "how_to_earn_privilege_3_popup_description_time": `Remarks: The daily cut off time for “e-Cash” and “Bonus Points” is 00:00 based on the time of Café de Coral’s computer system.`,
  "how_to_earn_privilege_4_header": `Festive and Birthday Offers`,
  "how_to_earn_privilege_4_description": `Enjoy festive offers for handpicked products and birthday e-Coupon!`,
  "how_to_earn_privilege_5_header": `Happy Referral Reward`,
  "how_to_earn_privilege_5_description": `Earn $10 e-Coupon for the referral of every 5 new members successfully.`,
  "how_to_earn_privilege_5_popup_header": `Happy Referral Reward`,
  "how_to_earn_privilege_5_popup_description": `Log in & click "Me" button and then press "Refer (+)" button and invite your friend by sending the referral link!`,
  "how_to_earn_scroll_member_privilege": `Member Privileges`,
  "how_to_earn_scoll_how_to_register": `How to Register`,
  "how_to_register_header": `Join Club 100 now`,
  "how_to_register_step_1_header": `Click Login/Register`,
  "how_to_register_step_1_description": `Click "Login/Register" and then enter mobile no.`,
  "how_to_register_step_2_header": `Fill in personal information`,
  "how_to_register_step_2_description": `Input verification code and fill in personal information`,
  "how_to_register_step_3_header": `Receive Gift!`,
  "how_to_register_step_3_description": `After registration, you will receive $30 e-Coupons`,
  "how_to_register_step_3_popup_header": `Welcome Gift `,
  "how_to_register_step_3_popup_description": `Receive six $5 e-Coupons within 1 working day upon successful registration.`,
  "how_to_register_step_3_popup_description_remarks": `*Terms & Conditions apply`,
  "how_to_register_register_now": `Register Now`,
  "how_to_register_member_activation": `Activate Membership`,
};

export default labelList;