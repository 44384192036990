import * as CONSTANT from '../constant';

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return CONSTANT.PHONE_OS_TYPE.WINDOWS;
    }

    if (/android/i.test(userAgent)) {
        return CONSTANT.PHONE_OS_TYPE.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return CONSTANT.PHONE_OS_TYPE.IOS;
    }

    if (/Mac/i.test(userAgent)) {
        return CONSTANT.PHONE_OS_TYPE.MAC;
    }

    return CONSTANT.PHONE_OS_TYPE.OTHERS;
}

export const redirectToAppStore = () => {
    setTimeout(() => {
        let os = getMobileOperatingSystem();
        if (os === CONSTANT.PHONE_OS_TYPE.IOS || os === CONSTANT.PHONE_OS_TYPE.MAC) {
            window.location.href = "https://apps.apple.com/hk/app/caf%C3%A9-de-coral/id1436965382"; 
        }else{
            window.location.href = "https://play.google.com/store/apps/details?id=com.cdc.cdcmember";
        }
    }, 2000);
}