const labelList = {

  // App name
  "app_name": `Club 100`,

  //How to Earn
  "how_to_earn_header": `大家樂Club 100一齊Joy，不停賞！`,
  "how_to_earn_sub_header": `一切禮遇，由成為Club 100會員開始。`,
  "how_to_earn_register": `立即登記`,
  "how_to_earn_privilege_1_header": `HK$30電子優惠券`,
  "how_to_earn_privilege_1_description": `6張$5電子現金券將於登記完成後的1個工作天內存入會員帳戶內。`,
  "how_to_earn_privilege_1_tnc": `受條款及細則約束。`,
  "how_to_earn_privilege_2_header": `每週消費獎賞`,
  "how_to_earn_privilege_2_description": `於任何時段以會員帳戶惠顧滿$27 = 1 次合資格消費`,
  "how_to_earn_privilege_2_description_calculation": `每週 (星期一至日計算)：`,
  "how_to_earn_privilege_2_description_calculation_3_times": `滿3次`,
  "how_to_earn_privilege_2_description_calculation_3_times_reward": `賞$5樂賞錢`,
  "how_to_earn_privilege_2_description_calculation_5_times": `滿5次`,
  "how_to_earn_privilege_2_description_calculation_5_times_reward": `再賞$10 樂賞錢`,
  "how_to_earn_privilege_2_popup_header": `每週消費獎賞備註`,
  "how_to_earn_privilege_2_popup_description": `會員每日第1次至第15次滿指定金額的消費，均可賺取「樂賞錢」，每日第16次或以後的消費則不會獲贈「樂賞錢」。`,
  "how_to_earn_privilege_2_popup_description_time": `「樂賞錢」每日的截數時間為00:00，並以大家樂電腦系統的時間為準。`,
  "how_to_earn_privilege_2_popup_description_remarks": `* (滿5次後或逢星期一將重新計算)`,
  "how_to_earn_privilege_3_header": `「樂賞分」獎賞`,
  "how_to_earn_privilege_3_description": `會員可於CafedeCoral手機應用程式以「樂賞分」兌換電子優惠券或禮物`,
  "how_to_earn_privilege_3_description_calculation": `$1 = 1樂賞分`,
  "how_to_earn_privilege_3_popup_header": `「樂賞分」獎賞備註`,
  "how_to_earn_privilege_3_popup_description": `會員每日第1次至第15次消費，均可賺取「樂賞分」，每日第16次或以後的消費則不會獲贈「樂賞分」。`,
  "how_to_earn_privilege_3_popup_description_time": `「樂賞分」每日的截數時間為00:00，並以大家樂電腦系統的時間為準。`,
  "how_to_earn_privilege_4_header": `節日及生日驚喜`,
  "how_to_earn_privilege_4_description": `尊享節日美食優惠及生日電子優惠券！`,
  "how_to_earn_privilege_5_header": `快樂同享 推薦有賞`,
  "how_to_earn_privilege_5_description": `成功推薦5名親友登記成為會員，即賞$10電子現金券！`,
  "how_to_earn_privilege_5_popup_header": `推薦有賞備註`,
  "how_to_earn_privilege_5_popup_description": `登入後按「帳戶」，再按「推薦會員(+)」按鈕，即可發送推薦連結給親友！`,
  "how_to_earn_scroll_member_privilege": `會員禮遇`,
  "how_to_earn_scoll_how_to_register": `如何登記`,
  "how_to_register_header": `如何登記Club 100`,
  "how_to_register_step_1_header": `按登入/登記`,
  "how_to_register_step_1_description": `按「登入/登記」，然後輸入手機號碼。`,
  "how_to_register_step_2_header": `輸入簡單資料`,
  "how_to_register_step_2_description": `輸入驗證碼，然後填寫簡單的個人資料。`,
  "how_to_register_step_3_header": `接收禮物！`,
  "how_to_register_step_3_description": `登記完成後，您將隨即獲贈總值$30電子現金券。`,
  "how_to_register_step_3_popup_header": `迎新禮物備註`,
  "how_to_register_step_3_popup_description": `6張$5電子現金券將於登記完成後的1個工作天內存入會員帳戶內。`,
  "how_to_register_step_3_popup_description_remarks": `*受條款及細則約束`,
  "how_to_register_register_now": `立即登記`,
  "how_to_register_member_activation": `啟動會員帳戶`,
};

export default labelList;