import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './container/home';
import './util/i18n/index';

import './App.css';

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" name="Home" element={<Home />} />
        <Route exact path="/:lang" name="Home" element={<Home />} />
        <Route path="*" name="Home" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
